.root {
  display: grid;
}

.tabs {
  display: flex;
  font-weight: 500;

  > div {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding: 8px 16px;
    border-bottom: solid 0.25rem var(--color-border);
    transition: border-color 0.2s;
    &:hover {
      border-color: var(--background5);
    }
    &:first-child {
      padding-left: 0;
    }
    &[aria-selected='true'] {
      border-bottom-color: var(--color-border-active);
    }
  }
}

.small {
  font-size: 1rem;
}

.line {
  height: 6px;
  background-color: black;
}
