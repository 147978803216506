@mixin styles {
  h1 {
    font-size: 3.375rem;
    line-height: 4rem;
    letter-spacing: -0.075rem;
  }

  h2 {
    font-size: 2.625rem;
    line-height: 3.125rem;
    letter-spacing: -0.05rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.025rem;
  }

  h4 {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    > li {
      margin-left: 28px;
    }
  }
}
