@use 'sass:map';
@use './variables';
@use './mixins';
@use './typography';

$paddingBase: 16; // TODO: Confirm this is correct
$marginBase: 16; // TODO: Confirm this is correct
$borderRadiusBase: 16; // TODO: Confirm this is correct
$borderRadiusFull: 100%;

$shadowStandard: 0 3 14 rgba(94, 119, 133, 0.25);

// hsl(224.9deg 100% 70.39%)

:root {
  @each $name, $color in variables.$light_colors {
    --#{$name}: #{$color};
  }
  @each $name, $color in variables.$light_gradients {
    --#{$name}: #{$color};
  }
  --grid-base: 16px;
  --navbar-height: 60px;
  --sidebar-w: 180px;
  --content-width: calc(100% - 180px);
  background-color: var(--screenBg);

  input:-webkit-autofill {
    -webkit-text-fill-color: black;
  }
  color: var(--color-fg-default);
  caret-color: black;
  --sidebar-w: 250px;
  --base-border-color: #ebebeb;
  --color-fade1: #64666b;
  --color-blue-accent: hsl(227.11deg 89.4% 70.39%);
  --color-blue-accent-light: hsl(227.29deg 100% 76.86%);
}

@include typography.styles;
