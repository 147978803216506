.root {
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
}

.header {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--grid-base);
  z-index: 2;
  padding: 10px 20px 0 20px;
  overflow: hidden;
  margin: 0 -20px;
}

.header > div {
  background-color: var(--background3);
  border-radius: 8px 8px 0px 0px;
  padding: 14px 24px;
  cursor: pointer;
  box-shadow: 0px 3px 14px rgba(94, 119, 133, 0.25);
  color: var(--background15);
  transform: translateY(10px);
  transition:
    color 0.2s,
    transform 0.2s;
}

.header > div[aria-selected='true'],
.header > div[data-selected] {
  color: var(--background14);
  background: var(--background4);
  transform: translateY(0px);
}

.header > div[data-disabled] {
  opacity: 0.7;
  cursor: default;
}

.contentWrap {
  display: grid;
  position: relative;
  .content {
    grid-gap: var(--grid-base);
    display: grid;
    background-color: var(--background4);
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 3px 14px rgb(94 119 133 / 25%);
    padding: 12px;
    overflow-y: auto;
  }

  .fader {
    color: var(--background4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 32px;
    background-image: linear-gradient(currentColor, rgb(0 0 0/ 0%));
    z-index: 2;
    pointer-events: none;
  }
}

:global(.dark) {
  .header > div {
    box-shadow: none;
  }
  .contentWrap {
    .content {
      box-shadow: none;
    }
  }
}
