html {
  font-size: 16px;
}

body {
  font-family: 'Basier Circle', Fallback, sans-serif;
  overflow-x: hidden;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  background-clip: text;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media screen and (max-width: 880px) {
  .rotate-pattern-y {
    /* transform: rotateY(50deg); */
    bottom: 10rem;
    top: auto;
    left: 0.8rem;
  }

  .rotate-pattern-x {
    /* transform: rotateX(50deg); */
    bottom: 0.5rem;
    right: 2.5rem;
    opacity: 0.7;
  }
}

.hide-scroll::-webkit-scrollbar {
  width: 0px !important;
}

@media (min-width: 200px) and (max-width: 640px) {
  body {
    min-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
  }
}

.setting-view {
  height: calc(100vh - 6.5rem);
}

@media (min-width: 1440px) {
  .setting-view {
    width: 33.438rem;
  }
}

@media (min-width: 1024px) {
  .setting-view {
    width: 33.438rem;
  }
}

@media (max-width: 640px) {
  .setting-view {
    min-height: auto;
    height: auto;
  }

  .box-shadow-top {
    box-shadow: 0px -10px 15px rgb(55 80 94 / 10%) !important;
  }
}

.z-60 {
  z-index: 60;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes useRippleAnimation {
  to {
    transform: scale(15);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.swiper-slide {
  transition-property: transform, opacity !important;
  transition-duration: 0.4s;
}

.swiper-button-disabled {
  opacity: 0.4;
  cursor: default !important;
}

.swiper {
  height: 100%;
}

.report-document {
  ol {
    padding-left: 1.5em;
  }

  li {
    list-style-type: none;
    padding-left: 1.5em;
    position: relative;
    > .ql-ui:before {
      display: inline-block;
      margin-left: -1.5em;
      margin-right: 0.3em;
      text-align: right;
      white-space: nowrap;
      width: 1.2em;
    }
  }

  li.ql-indent-1 {
    padding-left: 4.5em;
  }

  .ql-align-right {
    text-align: right;
  }

  li[data-list='bullet'] > .ql-ui:before {
    content: '\2022';
  }
}

.ql-toolbar.ql-snow {
  border: none;
}

.shadow-general {
  box-shadow: 0px -3px 15px rgba(55, 80, 94, 0.1);
}

li[role='option'] {
  margin-left: 0;
}

.ProseMirror-focused {
  outline: none;
}
